import { H1Bold } from '../components/shared/typography';
import React from 'react';
import { StyledContent } from '../components/layout/layout';

function NotFoundPage() {


  return (
    <StyledContent>
      <H1Bold style={{marginTop: '200px'}}>Hovsa! Denne side eksisterer ikke :-(</H1Bold>
    </StyledContent>
  );
}

export default NotFoundPage;
